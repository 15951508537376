/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
// @import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: #ddd;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  .logo {
    width: 100px;
    height: 100px;

    &.mji {
      background: url("./assets/img/logo/mini-logo-dim.png") center center
      no-repeat;
      background-size: 100%;
    }
  }
}
.auth0-lock a {
  display: unset !important;
  flex-direction: unset !important;
  justify-content: unset;
  align-items: unset;
  padding-left: unset;
  padding-right: unset;
  padding-top: unset;
  padding-bottom: unset;
  gap: unset;
  border-radius: unset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  letter-spacing: unset;
  white-space: unset;
  display: block;
  border: 1px solid transparent;
}

.auth0-lock.auth0-lock .auth0-lock-submit {
  justify-content: center;
}

ol > li {
  font-family: 'Roboto', sans-serif;
}

.auth0-lock .auth0-lock-sign-up-terms-agreement label input {
  float: left
}

@media (max-width: 790px) {
  .samsung-browser *>grid-layout>*[off-bottom] {
    margin-bottom: 180px !important;
  }
  *>grid-layout>*[off-bottom] {
    margin-bottom: 80px !important;
  }
}